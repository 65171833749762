<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <Snackbar />
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, provide, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import Snackbar from '@/components/Snackbar.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

// Other libraries
import store from '@/store'
import { can } from '@core/libs/acl/utils'
import notificationService from '@/services/notification.service'
import snackbarService from '@/services/snackbar.service'
import { useUtils } from '@core/libs/i18n'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
    Snackbar,
  },
  setup() {
    provide('store', store)
    provide('snackbarService', snackbarService)
    provide('can', can)
    const { t } = useUtils()
    provide('t', t)

    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    // Prefetch
    const fetchOrganization = () => {
      store
        .dispatch('fetchOrganization')
        .then(response => {
          const { data } = response.data
          store.dispatch('setOrganization', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching organizations. Please refresh!')
        })
    }
    const fetchBranches = () => {
      store
        .dispatch('fetchBranches')
        .then(response => {
          const { data } = response.data
          store.dispatch('setBranches', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching branches. Please refresh!')
        })
    }
    const fetchAccounts = () => {
      store
        .dispatch('fetchAccounts')
        .then(response => {
          const { data } = response.data
          store.dispatch('setAccounts', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          store.dispatch('setInsuranceProducts', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('fetchInsurances')
        .then(response => {
          const { data } = response.data
          store.dispatch('setInsurances', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPendingRequests = () => {
      store
        .dispatch('fetchPendingRequests')
        .then(response => {
          const { data } = response.data
          store.dispatch('setPendingRequests', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending requests. Please refresh!')
        })
    }
    const fetchPendingSpd = () => {
      store
        .dispatch('fetchPendingSpd')
        .then(response => {
          const { data } = response.data
          store.dispatch('setPendingSpd', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending spd receipts. Please refresh!')
        })
    }

    onMounted(() => {
      if (localStorage.getItem('userData') && localStorage.getItem('userAbility')) {
        fetchOrganization()
        fetchBranches()
        fetchAccounts()
        fetchInsuranceProducts()
        fetchInsurances()
        fetchPendingRequests()
        fetchPendingSpd()
        notificationService.subscribe()
      }
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
