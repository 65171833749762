import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import snackbarService from '@/services/snackbar.service'
import pages from './pages'

Vue.use(VueRouter)

const routes = [

  //
  //* ——— Home ——————————————————
  //

  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userRole === 'Auditor') return { name: 'dashboard' }
      if (userRole === 'Clerk') return { name: 'account-dashboard' }
      if (userRole === 'SPD reporter') return { name: 'account-dashboard' }
      if (userRole === 'SPD & Runner') return { name: 'account-dashboard' }
      if (userRole === 'Support Staff') return { name: 'account-dashboard' }
      if (userRole === 'Merchant') return { name: 'apps-loyalty-list' }
      if (userRole === 'Runner') return { name: 'apps-runner-list' }
      if (userRole === 'Admin') return { name: 'admin-user-list' }

      return { name: 'auth-login', query: to.query }
    },
  },

  //
  //* ——— Error 404 ——————————————————
  //

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  //
  //* ——— Login ——————————————————
  //

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  //
  //* ——— Dashboard ——————————————————
  //

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Dashboard',
    },
  },

  //
  //* ——— Clerk Dashboard ——————————————————
  //

  {
    path: '/account-dashboard',
    name: 'account-dashboard',
    component: () => import('@/views/dashboard/AccountDashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileDashboard',
    },
  },

  //
  //* ——— Receipts ——————————————————
  //

  {
    path: '/apps/receipts/list',
    name: 'apps-receipt-list',
    component: () => import('@/views/apps/receipt/ReceiptList.vue'),
    meta: {
      layout: 'content',
      resource: 'Receipt',
    },
  },
  {
    path: '/apps/spd/list',
    name: 'apps-spd-list',
    component: () => import('@/views/apps/spd/SpdList.vue'),
    meta: {
      layout: 'content',
      resource: 'SpdReport',
    },
  },
  {
    path: '/apps/receipts/add',
    name: 'apps-receipt-add',
    component: () => import('@/views/apps/receipt/ReceiptAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'Receipt',
      action: 'write',
    },
  },
  {
    path: '/apps/receipts/preview/:id',
    name: 'apps-receipt-preview',
    component: () => import('@/views/apps/receipt/ReceiptPreview.vue'),
    meta: {
      layout: 'content',
      resource: 'Receipt',
    },
  },
  {
    path: '/apps/receipts/edit/:id',
    name: 'apps-receipt-edit',
    component: () => import('@/views/apps/receipt/ReceiptEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Receipt',
    },
  },
  {
    path: '/apps/receipts/request/:id',
    name: 'apps-receipt-req',
    component: () => import('@/views/apps/receipt/ReceiptEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Receipt',
    },
  },

  //
  //* ——— Invoices ——————————————————
  //

  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/InvoiceList.vue'),
    meta: {
      layout: 'content',
      resource: 'Invoice',
    },
  },
  {
    path: '/apps/invoice/add',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/InvoiceAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'Invoice',
      action: 'write',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/InvoicePreview.vue'),
    meta: {
      layout: 'content',
      resource: 'Invoice',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Invoice',
    },
  },
  {
    path: '/apps/invoice/request/:id',
    name: 'apps-invoice-req',
    component: () => import('@/views/apps/invoice/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Invoice',
    },
  },

  //
  //* ——— Quotations ——————————————————
  //

  {
    path: '/apps/quotations/list',
    name: 'apps-quotation-list',
    component: () => import('@/views/apps/quotation/QuotationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotation',
    },
  },
  {
    path: '/apps/quotation/add',
    name: 'apps-quotation-add',
    component: () => import('@/views/apps/quotation/QuotationAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotation',
      action: 'write',
    },
  },
  {
    path: '/apps/quotation/preview/:id',
    name: 'apps-quotation-preview',
    component: () => import('@/views/apps/quotation/QuotationPreview.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotation',
    },
  },
  {
    path: '/apps/quotation/edit/:id',
    name: 'apps-quotation-edit',
    component: () => import('@/views/apps/quotation/QuotationEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotation',
    },
  },
  {
    path: '/apps/quotation/request/:id',
    name: 'apps-quotation-req',
    component: () => import('@/views/apps/quotation/QuotationEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotation',
    },
  },

  //
  //* ——— Commissions ——————————————————
  //

  {
    path: '/apps/commissions/list',
    name: 'apps-commission-list',
    component: () => import('@/views/apps/commission/CommissionList.vue'),
    meta: {
      layout: 'content',
      resource: 'CommissionStats',
    },
  },

  //
  //* ——— Profile Creation ——————————————————
  //

  {
    path: '/apps/profile-creation/list',
    name: 'apps-profile-creation-list',
    component: () => import('@/views/apps/profile-creation/ProfileCreationList.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/broker/add',
    name: 'apps-profile-creation-broker-add',
    component: () => import('@/views/apps/profile-creation/BrokerAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
      action: 'write',
    },
  },
  {
    path: '/apps/profile-creation/corporate/add',
    name: 'apps-profile-creation-corporate-add',
    component: () => import('@/views/apps/profile-creation/CorporateAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
      action: 'write',
    },
  },
  {
    path: '/apps/profile-creation/loyalty/add',
    name: 'apps-profile-creation-loyalty-add',
    component: () => import('@/views/apps/profile-creation/LoyaltyAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
      action: 'write',
    },
  },
  {
    path: '/apps/profile-creation/merchant/add',
    name: 'apps-profile-creation-merchant-add',
    component: () => import('@/views/apps/profile-creation/MerchantAdd.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
      action: 'write',
    },
  },
  {
    path: '/apps/profile-creation/broker/edit/:id',
    name: 'apps-profile-creation-broker-edit',
    component: () => import('@/views/apps/profile-creation/BrokerEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/corporate/edit/:id',
    name: 'apps-profile-creation-corporate-edit',
    component: () => import('@/views/apps/profile-creation/CorporateEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/loyalty/edit/:id',
    name: 'apps-profile-creation-loyalty-edit',
    component: () => import('@/views/apps/profile-creation/LoyaltyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/merchant/edit/:id',
    name: 'apps-profile-creation-merchant-edit',
    component: () => import('@/views/apps/profile-creation/MerchantEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/broker/request/:id',
    name: 'apps-profile-creation-broker-req',
    component: () => import('@/views/apps/profile-creation/BrokerEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/corporate/request/:id',
    name: 'apps-profile-creation-corporate-req',
    component: () => import('@/views/apps/profile-creation/CorporateEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/loyalty/request/:id',
    name: 'apps-profile-creation-loyalty-req',
    component: () => import('@/views/apps/profile-creation/LoyaltyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/profile-creation/merchant/request/:id',
    name: 'apps-profile-creation-merchant-req',
    component: () => import('@/views/apps/profile-creation/MerchantEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'ProfileCreation',
    },
  },
  {
    path: '/apps/loyalty/list',
    name: 'apps-loyalty-list',
    component: () => import('@/views/apps/profile-creation/LoyaltyList.vue'),
    meta: {
      layout: 'content',
      resource: 'LoyaltyList',
    },
  },

  //
  //* ——— Logs ——————————————————
  //

  {
    path: '/apps/logs/list',
    name: 'apps-log-list',
    component: () => import('@/views/apps/log/LogList.vue'),
    meta: {
      layout: 'content',
      resource: 'Logs',
    },
  },

  //
  //* ——— Reminder ——————————————————
  //

  {
    path: '/apps/reminder',
    name: 'apps-reminder-list',
    component: () => import('@/views/apps/reminder/ReminderList.vue'),
    meta: {
      layout: 'content',
      resource: 'Reminder',
    },
  },

  //
  //* ——— Promotions ————————————
  //

  {
    path: '/apps/banners/list',
    name: 'apps-banner-list',
    component: () => import('@/views/apps/website/BannerList.vue'),
    meta: {
      layout: 'content',
      resource: 'Website',
    },
  },

  //
  //* ——— Vouchers ——————————————
  //

  {
    path: '/apps/vouchers/list',
    name: 'apps-voucher-list',
    component: () => import('@/views/apps/voucher/VoucherList.vue'),
    meta: {
      layout: 'content',
      resource: 'Voucher',
    },
  },

  //
  //* ——— Runner ——————————————
  //

  {
    path: '/apps/runner/list',
    name: 'apps-runner-list',
    component: () => import('@/views/apps/runner/RunnerList.vue'),
    meta: {
      layout: 'content',
      resource: 'Runner',
    },
  },

  //
  //* ——— Requests ——————————————
  //

  {
    path: '/apps/requests/list',
    name: 'apps-request-list',
    component: () => import('@/views/apps/request/RequestList.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/invoice/:request_id',
    name: 'apps-request-edit-i',
    component: () => import('@/views/apps/invoice/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/receipt/:request_id',
    name: 'apps-request-edit-r',
    component: () => import('@/views/apps/receipt/ReceiptEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/quotation/:request_id',
    name: 'apps-request-edit-q',
    component: () => import('@/views/apps/quotation/QuotationEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/broker/:request_id',
    name: 'apps-request-edit-b',
    component: () => import('@/views/apps/profile-creation/BrokerEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/corporate/:request_id',
    name: 'apps-request-edit-c',
    component: () => import('@/views/apps/profile-creation/CorporateEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/loyalty/:request_id',
    name: 'apps-request-edit-l',
    component: () => import('@/views/apps/profile-creation/LoyaltyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/edit/merchant/:request_id',
    name: 'apps-request-edit-m',
    component: () => import('@/views/apps/profile-creation/MerchantEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/invoice/:request_id',
    name: 'apps-request-view-i',
    component: () => import('@/views/apps/invoice/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/receipt/:request_id',
    name: 'apps-request-view-r',
    component: () => import('@/views/apps/receipt/ReceiptEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/quotation/:request_id',
    name: 'apps-request-view-q',
    component: () => import('@/views/apps/quotation/QuotationEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/broker/:request_id',
    name: 'apps-request-view-b',
    component: () => import('@/views/apps/profile-creation/BrokerEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/corporate/:request_id',
    name: 'apps-request-view-c',
    component: () => import('@/views/apps/profile-creation/CorporateEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/loyalty/:request_id',
    name: 'apps-request-view-l',
    component: () => import('@/views/apps/profile-creation/LoyaltyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/view/merchant/:request_id',
    name: 'apps-request-view-m',
    component: () => import('@/views/apps/profile-creation/MerchantEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/invoice/:request_id',
    name: 'apps-request-void-i',
    component: () => import('@/views/apps/invoice/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/receipt/:request_id',
    name: 'apps-request-void-r',
    component: () => import('@/views/apps/receipt/ReceiptEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/quotation/:request_id',
    name: 'apps-request-void-q',
    component: () => import('@/views/apps/quotation/QuotationEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/broker/:request_id',
    name: 'apps-request-void-b',
    component: () => import('@/views/apps/profile-creation/BrokerEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/corporate/:request_id',
    name: 'apps-request-void-c',
    component: () => import('@/views/apps/profile-creation/CorporateEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/loyalty/:request_id',
    name: 'apps-request-void-l',
    component: () => import('@/views/apps/profile-creation/LoyaltyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },
  {
    path: '/apps/requests/void/merchant/:request_id',
    name: 'apps-request-void-m',
    component: () => import('@/views/apps/profile-creation/MerchantEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Request',
    },
  },

  //
  //* ——— Users ——————————————————
  //

  {
    path: '/admin/users/list',
    name: 'admin-user-list',
    component: () => import('@/views/admin/user/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },

  //
  //* ——— Miscellaneous ——————————————————
  //

  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const userRole = userData && userData.role ? userData.role : null
  const isLoggedIn = userData && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) next('/')

  if (userRole === 'Auditor' || userRole === 'Support Staff' || userRole === 'SPD & Runner') {
    store
      .dispatch('fetchPendingRequests')
      .then(response => {
        const { data } = response.data
        store.dispatch('setPendingRequests', data)
      })
      .catch(error => {
        snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending requests. Please refresh!')
      })
  }
  if (userRole === 'SPD reporter' || userRole === 'Support Staff' || userRole === 'SPD & Runner') {
    store
      .dispatch('fetchPendingSpd')
      .then(response => {
        const { data } = response.data
        store.dispatch('setPendingSpd', data)
      })
      .catch(error => {
        snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending spd receipts. Please refresh!')
      })
  }

  return next()
})

export default router
