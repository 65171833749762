import store from '@/store'

export default {
  success(content) {
    store.dispatch('showSnackbar', { content, status: 'success' })
  },
  error(content, timeout = 5000) {
    store.dispatch('showSnackbar', { content, status: 'error', timeout })
  },
  warning(content) {
    store.dispatch('showSnackbar', { content, status: 'warning' })
  },
  info(content) {
    store.dispatch('showSnackbar', { content, status: 'info' })
  },
}
