import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import encryptionService from '@/services/encryption.service'
import { axios, baseURL } from '@axios'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

import voucherStoreModule from './receipt/voucherStoreModule'
import requestStoreModule from './request/requestStoreModule'
import customerStoreModule from './customer/customerStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    oranization: {},
    branches: [],
    accounts: [],
    insuranceProducts: [],
    insurances: [],
    pendingRequests: {},
    pendingSpd: 0,
    roles: [],
    snackbar: {
      content: '',
      status: '',
      timeout: -1,
    },
  },
  getters: {
    isSnackbarVisible(state) {
      return state.snackbar.isSnackbarVisible
    },
  },
  mutations: {
    SHOW_SNACKBAR(state, value) {
      state.snackbar.content = value.content
      state.snackbar.status = value.status
      state.snackbar.timeout = value.timeout
    },
    SET_ORGANIZATION(state, value) {
      state.organization = value
    },
    SET_BRANCHES(state, value) {
      state.branches = value
    },
    SET_ACCOUNTS(state, value) {
      state.accounts = value
    },
    SET_INSURANCE_PRODUCTS(state, value) {
      state.insuranceProducts = value
    },
    SET_INSURANCES(state, value) {
      state.insurances = value
    },
    SET_PENDING_REQUESTS(state, value) {
      state.pendingRequests = value
    },
    SET_PENDING_SPD(state, value) {
      state.pendingSpd = value
    },
    SET_ROLES(state, value) {
      state.roles = value
    },
  },
  actions: {
    showSnackbar({ commit }, value) {
      commit('SHOW_SNACKBAR', value)
    },
    fetchOrganization() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/organizations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setOrganization({ commit }, value) {
      commit('SET_ORGANIZATION', value)
    },
    fetchBranches() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/branches')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setBranches({ commit }, value) {
      commit('SET_BRANCHES', value)
    },
    fetchLogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/logs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAccounts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users/metas')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setAccounts({ commit }, value) {
      commit('SET_ACCOUNTS', value)
    },
    fetchInsuranceProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/products')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setInsuranceProducts({ commit }, value) {
      commit('SET_INSURANCE_PRODUCTS', value)
    },
    fetchInsurances() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/insurances')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setInsurances({ commit }, value) {
      commit('SET_INSURANCES', value)
    },
    fetchPendingRequests() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/pending_requests')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPendingRequests({ commit }, value) {
      commit('SET_PENDING_REQUESTS', value)
    },
    fetchPendingSpd() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/pending_spd')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPendingSpd({ commit }, value) {
      commit('SET_PENDING_SPD', value)
    },
    fetchAnalytics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/organizations/dashboard', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClerkAnalytics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/organizations/clerk_dashboard', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommissionStatistics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/commissions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommission(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/commissions/total', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClaims(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/commissions/claims', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printCommissionStatisticsReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        params.user_ids.forEach(userId => urlParams.append('user_ids[]', userId))
        if (params.start_date) urlParams.append('start_date', params.start_date)
        if (params.end_date) urlParams.append('end_date', params.end_date)

        fetch(`${baseURL}/api/v1/commissions.${params.format}?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    printCommissionReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        if (params.start_date) urlParams.append('start_date', params.start_date)
        if (params.end_date) urlParams.append('end_date', params.end_date)

        fetch(`${baseURL}/api/v1/commissions/total.${params.format}?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    claimCommission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/commissions/claim', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/organizations/roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setRoles({ commit }, value) {
      commit('SET_ROLES', value)
    },
    updateUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/users/${params.user.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    voucherStore: voucherStoreModule,
    requestStore: requestStoreModule,
    customerStore: customerStoreModule,
    app,
  },
})
