import {
  mdiReceipt,
  mdiBroadcast,
  mdiFileDocumentOutline,
  mdiCurrencyUsd,
  mdiPen,
  mdiViewDashboardOutline,
  mdiDnsOutline,
  mdiLaptop,
  mdiCardMinus,
  mdiTooltipEditOutline,
  mdiCarOutline,
  mdiClockAlertOutline,
  mdiAccountBox,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboardOutline,
    to: { name: 'dashboard' },
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Dashboard',
    icon: mdiViewDashboardOutline,
    to: { name: 'account-dashboard' },
    resource: 'ProfileDashboard',
    action: 'read',
  },
  {
    title: 'Receipts',
    icon: mdiReceipt,
    to: { name: 'apps-receipt-list' },
    resource: 'Receipt',
    action: 'read',
  },
  {
    title: 'Invoices',
    icon: mdiFileDocumentOutline,
    to: { name: 'apps-invoice-list' },
    resource: 'Invoice',
    action: 'read',
  },
  {
    title: 'Quotations',
    icon: mdiCurrencyUsd,
    to: { name: 'apps-quotation-list' },
    resource: 'Quotation',
    action: 'read',
  },
  {
    title: 'SPD Reporting',
    icon: mdiPen,
    to: { name: 'apps-spd-list' },
    resource: 'SpdReport',
    action: 'read',
    badge: true,
    badgeColor: 'error',
    show: ['SPD reporter', 'Support Staff', 'SPD & Runner'],
  },
  {
    title: 'Profile Creation',
    icon: mdiAccountBox,
    to: { name: 'apps-profile-creation-list' },
    resource: 'ProfileCreation',
    action: 'read',
  },
  {
    title: 'Loyalty',
    icon: mdiAccountBox,
    to: { name: 'apps-loyalty-list' },
    resource: 'LoyaltyList',
    action: 'read',
  },
  {
    title: 'Reminder',
    icon: mdiClockAlertOutline,
    to: { name: 'apps-reminder-list' },
    resource: 'Reminder',
    action: 'read',
  },
  {
    title: 'Logs',
    icon: mdiBroadcast,
    to: { name: 'apps-log-list' },
    resource: 'Logs',
    action: 'read',
  },
  {
    title: 'Website',
    icon: mdiLaptop,
    resource: 'Website',
    action: 'read',
    children: [
      {
        title: 'Promotions',
        to: { name: 'apps-banner-list' },
        resource: 'Website',
        action: 'read',
      },
    ],
  },
  {
    title: 'Vouchers',
    icon: mdiCardMinus,
    to: { name: 'apps-voucher-list' },
    resource: 'Voucher',
    action: 'read',
  },
  {
    title: 'Runner',
    icon: mdiCarOutline,
    to: { name: 'apps-runner-list' },
    resource: 'Runner',
    action: 'read',
  },
  {
    title: 'Commissions',
    icon: mdiCurrencyUsd,
    to: { name: 'apps-commission-list' },
    resource: 'CommissionStats',
    action: 'read',
  },
  {
    title: 'Requests',
    icon: mdiTooltipEditOutline,
    to: { name: 'apps-request-list' },
    resource: 'Request',
    action: 'read',
    badge: true,
    badgeColor: 'error',
    show: ['Auditor', 'Support Staff', 'SPD & Runner'],
  },
  {
    title: 'Admin',
    icon: mdiDnsOutline,
    to: { name: 'admin-user-list' },
    resource: 'Admin',
    action: 'read',
  },
]
