export default {
  Dashboard: 'Papan pemuka',
  Receipts: 'Resit',
  Invoices: 'Invois',
  Quotations: 'Sebutan Harga',
  Vouchers: 'Baucar',
  Requests: 'Permintaan',
  Logs: 'Catatan',
  Website: 'Website',
  Promotions: 'Promosi',
  'Under Maintenance': 'Di bawah penyelenggaraan',
  Error: 'Kesilapan',
  'Coming Soon': 'Akan datang',
  'Not Authorized': 'Tidak dibenarkan',

  // Table
  Datetime: 'Tempoh Masa',
  Description: 'Penghuraian',
  Status: 'Status',
  'Issue Date': 'Tarikh Dikeluarkan',
  'Due Date': 'Tarikh Akhir',
  'Insurance Policy No.': 'No. Polisi Insurans',
  Branch: 'Cawangan',
  Issuer: 'Kerani',
  'Car Registration No.': 'No. Pendaftaran Kereta',
  'Customer Name': 'Nama Pelanggan',
  'Company Name': 'Nama Syarikat',
  'Surplus Hibah': 'Lebihan Hibah',
  NCD: 'NCD',
  'Sum Insured': 'Jumlah Diinsuranskan',
  'Insurance Premium': 'Premium Insurans',
  'After Discount': 'Selepas Diskaun',
  'Net Payment': 'Pembayaran Bersih',
  'Road Tax': 'Cukai Jalan',
  'Additional Fees': 'Bayaran Tambahan',
  Total: 'Jumlah',
  Party: 'Pihak',
  'Payment Method': 'Cara Pembayaran',
  'Insurance Product': 'Produk Insurans',
  'SPD Reference No.': 'No Rujukan SPD',
  Insurance: 'Insurans',
  'Bluecard Finance': 'Kewangan Bluecard',
  'Pickup Location': 'Lokasi Pengambilan',
  Period: 'Tempoh',
  Profit: 'Keuntungan',
  'General Remarks': 'Kenyataan Umum',
  Actions: 'Tindakan',

  // Invoices
  'Create Invoice': 'Buat Invois',
  'Search Invoice': 'Cari Invois',
  '#Invoice No.': '#No. Invois',
  'Confirm Invoice': 'Simpan Invois',
  Outstanding: 'Belum Dijelaskan',
  Paid: 'Selesai',
  'This invoice has been voided.': 'Invois ini telah dibatalkan.',
  'invoice-create-limit': 'Anda telah mencapai had penciptaan. Sila selesaikan sebarang invois tertunggak sebelum anda boleh membuat invois baru.',

  // Receipts
  'Create Receipt': 'Buat Resit',
  'Search Receipt': 'Cari Resit',
  '#Receipt No.': '#No. Resit',
  'Confirm Receipt': 'Simpan Resit',
  'This receipt has been voided.': 'Resit ini telah dibatalkan.',
  'Payment Screenshot': 'Screenshot Pembayaran',
  'Delete voucher': 'Padam baucar',
  'receipt-create-limit': 'Anda telah mencapai had penciptaan 10 resit dalam sehari.',

  // Quotations
  'Create Quotation': 'Buat Sebutan Harga',
  'Search Quotation': 'Cari Sebutan Harga',
  '#Quotation No.': '#No. Sebutan Harga',
  'Confirm Quotation': 'Simpan Sebutan Harga',
  'This quotation has been voided.': 'Sebutan harga ini telah dibatalkan.',

  // Requests
  'Send Request': 'Hantar Permintaan',
  'Approve Request': 'Tetapkan Permintaan',
  'Reject Request': 'Tolak Permintaan',
  'Request Date': 'Tarikh Permintaan',
  'Record No.': 'No. Rekod',
  'Record Number': 'Nombor Rekod',
  'Record Type': 'Jenis Rekod',
  Requester: 'Peminta',
  'Requested Action': 'Tindakan Diminta',
  'Approval Date': 'Tarikh ditetap',
  'Approved by': 'Ditetapkan oleh',
  'Rejection Date': 'Tarikh ditolak',
  'Rejected by': 'Ditolak oleh',
  'Search Record': 'Cari Rekod',
  'Remove Request': 'Batal Permintaan',
  'Select Record Type': 'Care Jenis Rekod',
  'Pending requests': 'Permintaan belum selesai',
  'Approved requests': 'Permintaan yang diluluskan',
  'Rejected requests': 'Permintaan yang ditolak',
  'Approve All': 'Luluskan Semua',
  'Reject All': 'Tolak Semua',
  'Invoice has been voided, please remove this request as it cannot be saved.': 'Invois telah dibatalkan, sila alih keluar permintaan ini kerana ia tidak boleh disimpan.',
  'Receipt has been voided, please remove this request as it cannot be saved.': 'Resit telah dibatalkan, sila alih keluar permintaan ini kerana ia tidak boleh disimpan.',
  'Quotation has been voided, please remove this request as it cannot be saved.': 'Sebutharga telah dibatalkan, sila alih keluar permintaan ini kerana ia tidak boleh disimpan.',
  'Invoice has been voided, please inform requester to remove this request.': 'Invois telah dibatalkan, sila maklumkan peminta untuk mengalih keluar permintaan ini.',
  'Receipt has been voided, please inform requester to remove this request.': 'Resit telah dibatalkan, sila maklumkan peminta untuk mengalih keluar permintaan ini.',
  'Quotation has been voided, please inform requester to remove this request.': 'Sebutharga telah dibatalkan, sila maklumkan peminta untuk mengalih keluar permintaan ini.',

  // Website
  'Create Banner': 'Buat Banner',

  // Voucher
  Code: 'Kod',
  Amount: 'Jumlah',
  'Used At': 'Digunakan Pada',
  'Used By': 'Digunakan oleh',
  'Issued By': 'Diisukan oleh',
  'Receipt Link': 'Resit Link',
  'Search Voucher': 'Cari Baucar',
  'Select Amount': 'Pilih Jumlah',
  'Select Issue Type': 'Pilih Jenis Isu',
  'Voucher Discount': 'Diskaun Baucar',
  'Voucher Code': 'Kod baucar',

  // Misc
  Search: 'Cari',
  'Contact No.': 'Nombor Perhubungan',
  'Select Status': 'Pilih Status',
  'Select Branch': 'Pilih Cawangan',
  'Select Account': 'Pilih Akaun',
  'Set Dates': 'Tetapkan Haribulan',
  'Select Product Types': 'Pilih Jenis Produk',
  'Select Accounts': 'Pilih Akaun',
  'Select View': 'Pilih Paparan',
  'Select Grouping': 'Pilih Pengelompokan',
  'Select Insurances': 'Pilih Insurans',
  'Select Year': 'Pilih Tahun',
  Rows: 'Barisan',
  'Print Report': 'Cetak Laporan',
  Print: 'Cetak',
  'Print full report': 'Cetak laporan penuh',
  'Print runner report': 'Cetak laporan runner',
  'Print non-motor report': 'Cetak laporan bukan motor',
  'View Policy': 'Lihat Polisi',
  'Summary for': 'Ringkasan untuk',
  'Total collection by cash': 'Jumlah kutipan melalui cash',
  'Total collection by BIBD': 'Jumlah kutipan melalui BIBD',
  'Total collection by Baiduri': 'Jumlah kutipan melalui Baiduri',
  'Total collection by cheque': 'Jumlah kutipan melalui cek',
  'Total collection by card': 'Jumlah kutipan melalui kad',
  'Total overall collection': 'Jumlah kutipan kesuluruhan',
  'View Log': 'Lihat Catatan',
  Logout: 'Log Keluar',
  Today: 'Hari ini',
  'Last 7 days': '7 hari kebelakangan',
  'Last 1 year': '1 tahun kebelakangan',
  'Profit Breakdown': 'Pecahan Keuntungan',
  'Monthly Profit Summary': 'Ringkasan Keuntungan Bulanan',
  Day: 'Hari',
  Week: 'Minggu',
  Month: 'Bulan',
  'Expiration Date': 'Tarikh luput',
  Branches: 'Cawangan',
  Accounts: 'Akaun',
  Principles: 'Insurans',
  'Total Customer Payment': 'Jumlah Pembayaran Pelanggan',
  'Total Net Payment': 'Jmlah Pembayaran Bersih',
  'Total Profit': 'Jumlah Keuntungan',
  Settings: 'Tetapan',
  Account: 'Akaun',
  Nickname: 'Nama Panggilan',
  Name: 'Nama',
  'E-mail': 'E-mel',
  'Enter New Password': 'Masukkan Kata Laluan Baru',
  'Only change your password when necessary': 'Tukar kata laluan anda apabila perlu sahaja',
  Save: 'Simpan',
  'Please ensure that all fields are filled correctly as this record will not be editable after confirming.': 'Sila pastikan semua ruangan diisi dengan betul kerana rekod ini tidak akan dapat diedit setelah disimpan.',
  'All invoices need to be settled before Sales Report Collection.': 'Semua invois perlu dijelaskan sebelum Pengumpulan Laporan Jualan.',
  'How to use the search bar': 'Cara menggunakan bar carian',
  'You can search using': 'Anda boleh mencari menggunakan',
  'How to use Autofill': 'Cara menggunakan Autofill',
  'Press the autofill button and wait for the form to be filled automatically!': 'Tekan butang autofill dan tunggu sehingga borang diisi secara automatik!',
  'Select amount to issue a voucher to customer for future use': 'Pilih jumlah untuk mengeluarkan baucar kepada pelanggan gunakan masa hadapan',
  'Whatsapp Resend': 'Hantaran ulang Whatsapp',
  'SMS Resend': 'Hantaran ulang SMS',
  'SMS Send': 'Hantaran SMS',
  'Select SMS Send': 'Pilih Hantaran SMS',
  'Select Payment Method': 'Pilih Cara Pembayaran',
  Runner: 'Runner',
  Send: 'Hantar',
  'SMS Reminder': 'Peringatan SMS',
  'SMS Last Sent': 'Terakhir Dihantar',
  'Yes, send message': 'Ya, hantar mesej',
  'Send SMS message to contact number': 'Hantar mesej SMS ke nombor',
  'to notify their roadtax have arrived': 'untuk memaklumkan cukai jalan mereka telah tiba',
  'Only use this when customer did not receive any automated WhatsApp message': 'Hanya gunakan ini apabila pelanggan tidak menerima sebarang mesej WhatsApp automatik',
  "Only use this when customer did not receive any automated SMS message or don't have WhatsApp": 'Hanya gunakan ini apabila pelanggan tidak menerima sebarang mesej SMS automatik atau tidak mempunyai WhatsApp',

  // Roles
  Clerk: 'Kerani',
  Auditor: 'Juruaudit',
  Admin: 'Pentadbir',
}
