<template>
  <v-snackbar
    v-model="isSnackbarVisible"
    top
    text
    :light="$vuetify.theme.dark"
    :color="status"
    :timeout="timeout"
  >
    {{ content }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        :color="status"
        v-bind="attrs"
        @click="isSnackbarVisible = false"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from '@mdi/js'
import {
  ref,
  onMounted,
  computed,
  inject,
} from '@vue/composition-api'

export default {
  setup() {
    const store = inject('store')
    const isSnackbarVisible = ref(false)
    const content = computed(() => store.state.snackbar.content)
    const status = computed(() => store.state.snackbar.status)
    const timeout = computed(() => store.state.snackbar.timeout)

    onMounted(() => {
      store.subscribe(mutation => {
        if (mutation.type === 'SHOW_SNACKBAR') isSnackbarVisible.value = true
      })
    })

    return {
      isSnackbarVisible,
      content,
      status,
      timeout,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
