export default {
  Dashboard: 'Dashboard',
  Receipts: 'Receipts',
  Invoices: 'Invoices',
  Quotations: 'Quotations',
  Vouchers: 'Vouchers',
  Requests: 'Requests',
  Logs: 'Logs',
  Website: 'Website',
  Promotions: 'Promotions',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',

  // Table
  Datetime: 'Datetime',
  Description: 'Description',
  Status: 'Status',
  'Issue Date': 'Issue Date',
  'Due Date': 'Due Date',
  'Insurance Policy No.': 'Insurance Policy No.',
  Branch: 'Branch',
  Issuer: 'Issuer',
  'Car Registration No.': 'Car Registration No.',
  'Customer Name': 'Customer Name',
  'Company Name': 'Company Name',
  'Surplus Hibah': 'Surplus Hibah',
  NCD: 'NCD',
  'Sum Insured': 'Sum Insured',
  'Insurance Premium': 'Insurance Premium',
  'After Discount': 'After Discount',
  'Net Payment': 'Net Payment',
  'Road Tax': 'Road Tax',
  'Additional Fees': 'Additional Fees',
  Total: 'Total',
  Party: 'Party',
  'Payment Method': 'Payment Method',
  'Insurance Product': 'Insurance Product',
  'SPD Reference No.': 'SPD Reference No.',
  Insurance: 'Insurance',
  'Bluecard Finance': 'Bluecard Finance',
  'Pickup Location': 'Pickup Location',
  Period: 'Period',
  Profit: 'Profit',
  'General Remarks': 'General Remarks',
  Actions: 'Actions',

  // Invoices
  'Create Invoice': 'Create Invoice',
  'Search Invoice': 'Search Invoice',
  '#Invoice No.': '#Invoice No.',
  'Confirm Invoice': 'Confirm Invoice',
  Outstanding: 'Outstanding',
  Paid: 'Paid',
  'This invoice has been voided.': 'This invoice has been voided.',
  'invoice-create-limit': "You've reached the creation limit. Please settle any outstanding invoices before you can create more.",

  // Receipts
  'Create Receipt': 'Create Receipt',
  'Search Receipt': 'Search Receipt',
  '#Receipt No.': '#Receipt No.',
  'Confirm Receipt': 'Confirm Receipt',
  'This receipt has been voided.': 'This receipt has been voided.',
  'Payment Screenshot': 'Payment Screenshot',
  'Delete voucher': 'Delete voucher',
  'receipt-create-limit': "You've reached the creation limit of 10 receipts per day.",

  // Quotations
  'Create Quotation': 'Create Quotation',
  'Search Quotation': 'Search Quotation',
  '#Quotation No.': '#Quotation No.',
  'Confirm Quotation': 'Confirm Quotation',
  'This quotation has been voided.': 'This quotation has been voided.',

  // Requests
  'Send Request': 'Send Request',
  'Approve Request': 'Approve Request',
  'Reject Request': 'Reject Request',
  'Request Date': 'Request Date',
  'Record No.': 'Record No.',
  'Record Number': 'Record Number',
  'Record Type': 'Record Type',
  Requester: 'Requester',
  'Requested Action': 'Requested Action',
  'Approval Date': 'Approval Date',
  'Approved by': 'Approved by',
  'Rejection Date': 'Rejection Date',
  'Rejected by': 'Rejected by',
  'Search Record': 'Search Record',
  'Remove Request': 'Remove Request',
  'Select Record Type': 'Select Record Type',
  'Pending requests': 'Pending requests',
  'Approved requests': 'Approved requests',
  'Rejected requests': 'Rejected requests',
  'Approve All': 'Approve All',
  'Reject All': 'Reject All',
  'Invoice has been voided, please remove this request as it cannot be saved.': 'Invoice has been voided, please remove this request as it cannot be saved.',
  'Receipt has been voided, please remove this request as it cannot be saved.': 'Receipt has been voided, please remove this request as it cannot be saved.',
  'Quotation has been voided, please remove this request as it cannot be saved.': 'Quotation has been voided, please remove this request as it cannot be saved.',
  'Invoice has been voided, please inform requester to remove this request.': 'Invoice has been voided, please inform requester to remove this request.',
  'Receipt has been voided, please inform requester to remove this request.': 'Receipt has been voided, please inform requester to remove this request.',
  'Quotation has been voided, please inform requester to remove this request.': 'Quotation has been voided, please inform requester to remove this request.',

  // Website
  'Create Banner': 'Create Banner',

  // Voucher
  Code: 'Code',
  Amount: 'Amount',
  'Used At': 'Used At',
  'Used By': 'Used By',
  'Issued By': 'Issued By',
  'Receipt Link': 'Receipt Link',
  'Search Voucher': 'Search Voucher',
  'Select Amount': 'Select Amount',
  'Select Issue Type': 'Select Issue Type',
  'Voucher Discount': 'Voucher Discount',
  'Voucher Code': 'Voucher Code',

  // Misc
  Search: 'Search',
  'Contact No.': 'Contact No.',
  'Select Status': 'Select Status',
  'Select Branch': 'Select Branch',
  'Select Account': 'Select Account',
  'Set Dates': 'Set Dates',
  'Select Product Types': 'Select Product Types',
  'Select Accounts': 'Select Accounts',
  'Select View': 'Select View',
  'Select Grouping': 'Select Grouping',
  'Select Insurances': 'Select Insurances',
  'Select Year': 'Select Year',
  Rows: 'Rows',
  'Print Report': 'Print Report',
  Print: 'Print',
  'Print full report': 'Print full report',
  'Print runner report': 'Print runner report',
  'Print non-motor report': 'Print non-motor report',
  'View Policy': 'View Policy',
  'Summary for': 'Summary for',
  'Total collection by cash': 'Total collection by cash',
  'Total collection by BIBD': 'Total collection by BIBD',
  'Total collection by Baiduri': 'Total collection by Baiduri',
  'Total collection by cheque': 'Total collection by cheque',
  'Total collection by card': 'Total collection by card',
  'Total overall collection': 'Total overall collection',
  'View Log': 'View Log',
  Logout: 'Logout',
  Today: 'Today',
  'Last 7 days': 'Last 7 days',
  'Last 1 year': 'Last 1 year',
  'Profit Breakdown': 'Profit Breakdown',
  'Monthly Profit Summary': 'Monthly Profit Summary',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  'Expiration Date': 'Expiration Date',
  Branches: 'Branches',
  Accounts: 'Accounts',
  Principles: 'Principles',
  'Total Customer Payment': 'Total Customer Payment',
  'Total Net Payment': 'Total Net Payment',
  'Total Profit': 'Total Profit',
  Settings: 'Settings',
  Account: 'Account',
  Nickname: 'Nickname',
  Name: 'Name',
  'E-mail': 'E-mail',
  'Enter New Password': 'Enter New Password',
  'Only change your password when necessary': 'Only change your password when necessary',
  Save: 'Save',
  'Please ensure that all fields are filled correctly as this record will not be editable after confirming.': 'Please ensure that all fields are filled correctly as this record will not be editable after confirming.',
  'All invoices need to be settled before Sales Report Collection.': 'All invoices need to be settled before Sales Report Collection.',
  'How to use the search bar': 'How to use the search bar',
  'You can search using': 'You can search using',
  'How to use Autofill': 'How to use Autofill',
  'Press the autofill button and wait for the form to be filled automatically!': 'Press the autofill button and wait for the form to be filled automatically!',
  'Select amount to issue a voucher to customer for future use': 'Select amount to issue a voucher to customer for future use',
  'Whatsapp Resend': 'Whatsapp Resend',
  'SMS Resend': 'SMS Resend',
  'SMS Send': 'SMS Send',
  'Select SMS Send': 'Select SMS Send',
  'Select Payment Method': 'Select Payment Method',
  Runner: 'Runner',
  Send: 'Send',
  'SMS Reminder': 'SMS Reminder',
  'SMS Last Sent': 'SMS Last Sent',
  'Yes, send message': 'Yes, send message',
  'Send SMS message to contact number': 'Send SMS message to contact number',
  'to notify their roadtax have arrived': 'to notify their roadtax have arrived',
  'Only use this when customer did not receive any automated WhatsApp message': 'Only use this when customer did not receive any automated WhatsApp message',
  "Only use this when customer did not receive any automated SMS message or don't have WhatsApp": "Only use this when customer did not receive any automated SMS message or don't have WhatsApp",

  // Roles
  Clerk: 'Clerk',
  Auditor: 'Auditor',
  Admin: 'Admin',
}
