import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/approval_requests', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/approval_requests/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/approval_requests', { request: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/approval_requests/${params.id}`, { request: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/approval_requests/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/approval_requests/${params.id}/approve`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    rejectRequest(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/approval_requests/${params.id}/reject`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bulkAction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/approval_requests/bulk', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/approval_comments', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/approval_comments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createComment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/approval_comments', { comment: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateComment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/approval_comments/${params.id}`, { comment: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidComment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/approval_comments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport() {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/approval_requests.csv`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  },
}
