import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVouchers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/vouchers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkVoucher(ctx, { code }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/vouchers/${code}/check`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVoucher(ctx, { code }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/vouchers/${code}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVoucher(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/vouchers/${params.code}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidVoucher(ctx, { code }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/vouchers/${code}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
